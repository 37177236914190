import useMarketplaceChain from 'hooks/useMarketplaceChain'
import { Box, Flex, Text } from '../primitives'
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
  ComponentPropsWithoutRef,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import supportedChains from 'utils/chains'
import {
  useTrendingCollections,
  useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import { CollectionsSortingOption } from 'components/common/CollectionsTimeDropdown'
import { MintsSortingOption } from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import bg from '../../public/index/bgstarslarge.png'
import Image from 'next/image'
import Link from 'next/link'
import cat_1 from '../../public/index/teamgreen.png'
import cat_2 from '../../public/index/teamblue.png'
import cat_3 from '../../public/index/teamred.png'
import hero_image from '../../public/index/hero_image.jpg'
import logo_img from '../../public/index/logo.png'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
  const router = useRouter()
  const { routePrefix } = useMarketplaceChain()
  // not sure if there is a better way to fix this
  const { theme: nextTheme } = useTheme()
  const [theme, setTheme] = useState<string | null>(null)
  useEffect(() => {
    if (nextTheme) {
      setTheme(nextTheme)
    }
  }, [nextTheme])

  const isSSR = typeof window === 'undefined'
  const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

  const [tab, setTab] = useState<TabValue>('collections')
  const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

  const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
  let [countCard, setCountCard] = useState(0)
  let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
    limit: 20,
    period: sortByPeriod,
    type: 'any',
  }

  const { chain, switchCurrentChain } = useContext(ChainContext)

  useEffect(() => {
    if (router.query.chain) {
      let chainIndex: number | undefined
      for (let i = 0; i < supportedChains.length; i++) {
        if (supportedChains[i].routePrefix == router.query.chain) {
          chainIndex = supportedChains[i].id
        }
      }
      if (chainIndex !== -1 && chainIndex) {
        switchCurrentChain(chainIndex)
      }
    }
  }, [router.query])

  const {
    data: trendingCollections,
    isValidating: isTrendingCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'volume',
      period: sortByTime,
    },
    chain.id,
    {
      fallbackData: '',
      keepPreviousData: true,
    }
  )
  const {
    data: featuredCollections,
    isValidating: isFeaturedCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'sales',
      period: '24h',
    },
    chain.id,
    {
      fallbackData: '',
      keepPreviousData: true,
    }
  )
  const { data: trendingMints, isValidating: isTrendingMintsValidating } =
    useTrendingMints({ ...mintsQuery }, chain.id, {
      fallbackData: '',
      keepPreviousData: true,
    })
  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = '1day'

  switch (sortByTime) {
    case '30d':
      volumeKey = '30day'
      break
    case '7d':
      volumeKey = '7day'
      break
    case '24h':
      volumeKey = '1day'
      break
  }

  return (
    <div className="newHomeContent" style={{ position: 'relative' }}>
      <div className='bgImg' style={{ width: '100%', height: '100%' }}>
        <Image src={hero_image} alt="bh_img"></Image>
      </div>
      <div
        className='logoBox'
      >
        <div className='logo'>
          <div>
            <Image src={logo_img} alt="logo_img"></Image>
          </div>
          <div className='btnBox'>
              <Link
                href={`/${routePrefix}/collections/trending`}
                style={{
                  padding: '10px 20px',
                  margin: '10px',
                  fontSize: '1em',
                  fontFamily: 'Inter',
                  color: '#ffffff',
                  backgroundColor: '#fb4d18',
                  cursor: 'pointer',
                  outline: 'none',
                }}
              >
                <span
                  style={{
                    color: '#07080e',
                    fontSize: '16px',
                    fontWeight:"500",
                    letterSpacing:"3px"
                  }}
                >
                  EXPLORE
                </span>
              </Link>
              <Link
                href={`/${routePrefix}/explore`}
                style={{
                  padding: '10px 20px',
                  fontSize: '1em',
                  fontFamily: 'Inter',
                  color: '#07080e',
                  backgroundColor: '#fb4d18',
                  cursor: 'pointer',
                  outline: 'none',
                }}
              >
                <span
                  style={{
                    color: '#07080e',
                    fontSize: '16px',
                    fontWeight:"500",
                    letterSpacing:"3px"
                  }}
                >
                  COLLECTIONS
                </span>
              </Link>
          </div>
        </div>
      </div>
    </div>
    // <Box css={{
    //     width: "100%",
    //     // height: "100vh",
    //     background: `url(${bg.src}) center`,
    //     backgroundColor:"#000",
    //     backgroundSize: "cover",
    //     px: "$4"
    // }}>
    //     <Box css={{
    //         width: "100%",
    //         gap: 24,
    //         "@md": {
    //             // height: "100vh"
    //         }
    //     }}>
    //         <Flex align="center" justify="center">
    //             <Text style="h2" css={{
    //                 fontFamily: "'setbackt', courier, mono space",
    //                 color: "#88ee88",
    //                 textShadow: "4px 4px 0px #444",
    //                 textAlign: "center",
    //                 fontSize: "50px",
    //                 margin: "1em",
    //                 letterSpacing: "10px",
    //                 "@md": {
    //                     fontSize: "120px",
    //                 }
    //             }}>
    //                 SliceNFT
    //             </Text>
    //         </Flex>

    //         <Box css={{
    //             backgroundColor: "#fff",
    //             padding: "0.5em",
    //             boxShadow: "8px 8px 0px 0px #555555",
    //             overflowWrap: " break-word",
    //             width: "80%",
    //             margin: "0 auto"
    //         }}>

    //             <Text style="body1" css={{
    //                 color: "#4400bb",
    //                 fontSize: "1.5em",
    //                 fontFamily: 'pixelar, mono space, sans-serif',
    //                 textAlign: "left"
    //             }}>
    //                 MoonCats are an original NFT which launched in 2017 and helped pioneer <span style={{
    //                 color: "red"
    //             }}>on-chain generation,
    //                 fair distribution</span>, and <span style={{
    //                 color: "red"
    //             }}>user customization.</span> <Link
    //                 href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}
    //                 style={{
    //                     color: "#00aa00"
    //                 }}>More about the project...</Link>
    //             </Text>
    //         </Box>
    //         <Flex align="center" justify="center" css={{
    //             marginTop: '$6',
    //             flexDirection:"column",
    //             "@md": {
    //                 flexDirection:"row"
    //             }
    //         }}>
    //             <Image src={cat_1} alt=""/>
    //             <Image src={cat_2} alt=""/>
    //             <Image src={cat_3} alt=""/>
    //         </Flex>

    //         <Flex align="center"  css={{
    //             flexDirection:"column",
    //             "@md":{
    //                 width:"40%",
    //                 flexDirection:"row",
    //                 margin:"$6 auto",
    //                 justifyContent:"space-around"
    //             }
    //         }}>
    //             <Link href={`/${routePrefix}/collections/trending`} style={{
    //                 padding: "10px 20px",
    //                 margin: "10px",
    //                 fontSize: "1em",
    //                 fontFamily: "screen1",
    //                 color: "#ffffff",
    //                 backgroundColor: "green",
    //                 boxShadow: "8px 8px 0px 0px #444444",
    //                 cursor: "pointer",
    //                 outline: "none",
    //             }}>
    //                 <span style={{
    //                     color: "#FFFFFF",
    //                     fontSize: '20px',
    //                 }}>EXPLORE</span>
    //             </Link>
    //             <Link href={`/${routePrefix}/explore`} style={{
    //                 padding: "10px 20px",
    //                 marginRight: "10px",
    //                 fontSize: "1em",
    //                 fontFamily: "screen1",
    //                 color: "#ffffff",
    //                 backgroundColor: "blue",
    //                 boxShadow: "8px 8px 0px 0px #444444",
    //                 cursor: "pointer",
    //                 outline: "none",

    //             }}>
    //                 <span style={{
    //                     color: "#FFFFFF",
    //                     fontSize: '20px',
    //                 }}>COLLECTIONS</span>
    //             </Link>
    //         </Flex>
    //     </Box>
    //     <Box css={{
    //         height:"200px"
    //     }}>

    //     </Box>
    // </Box>
  )
}

type TrendingCollectionsSchema =
  paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
  paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
  ssr: {
    trendingMints: TrendingMintsSchema
    trendingCollections: TrendingCollectionsSchema
    featuredCollections: TrendingCollectionsSchema
  }
}> = async ({ params, res }) => {
  const chainPrefix = params?.chain || ''
  const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
  // init BaseApi
  // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
  // DefaultChain
  // console.log("请求的路劲",reservoirBaseUrl)
  const headers: RequestInit = {
    headers: {
      'x-api-key': process.env.RESERVOIR_API_KEY || '',
    },
  }
  let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      sortBy: 'volume',
    }

  const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
  )
  let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      sortBy: 'sales',
    }

  const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
  )

  let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      type: 'any',
    }

  const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
  )

  const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
  ]).catch((e) => {
    console.error(e)
  })

  const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
      ? (promises[0].value.data as Props['ssr']['trendingCollections'])
      : {}
  const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? (promises[1].value.data as Props['ssr']['featuredCollections'])
      : {}

  const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? (promises[1].value.data as Props['ssr']['trendingMints'])
      : {}

  res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
  )
  return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
  }
}
export default NFTBanner
